import {
	AdditionalLoanPurposeEnum,
	FinancialPlanConfig,
	NormalizedResource,
	PrimaryLoanPurposeEnum,
	ResourceType,
	Simulator,
	getFinancialPlanLoanAmountFields,
	getFinancialPlanOwnFundFields,
} from '@oper-client/shared/data-model';
import defaultSimulatorConfiguration from './default-simulator.configuration';
import { BrokerageFormEnum } from '@oper-client/shared/util-dynamic-form';

const configuration: Simulator.CustomerConfiguration = {
	...defaultSimulatorConfiguration,
	simulationPresets: (resources: NormalizedResource, financialPlanConfig: FinancialPlanConfig) => ({
		loanPurposes: resources?.[ResourceType.LOAN_REQUEST_PURPOSE].filter((purpose) => purpose.definition === 'purchase'),
		financialPlanLoanAmountItems: getFinancialPlanLoanAmountFields(financialPlanConfig),
		financialPlanOwnFundItems: getFinancialPlanOwnFundFields(financialPlanConfig),
	}),
	steps: [
		Simulator.SimulatorStepEnum.COSTS_AND_FINANCING,
		Simulator.SimulatorStepEnum.BORROWER_DETAILS,
		Simulator.SimulatorStepEnum.CREATE_REQUEST,
	],
	[Simulator.SimulatorStepEnum.COSTS_AND_FINANCING]: {
		...defaultSimulatorConfiguration[Simulator.SimulatorStepEnum.COSTS_AND_FINANCING],
		loanPurposes: [
			PrimaryLoanPurposeEnum.PURCHASE,
			PrimaryLoanPurposeEnum.NEW_BUILD,
			PrimaryLoanPurposeEnum.FREE_USE,
			AdditionalLoanPurposeEnum.RENOVATION,
		],
		resourceTypes: [
			ResourceType.LOAN_REQUEST_PURPOSE,
			ResourceType.RENOVATION_TYPE,
			ResourceType.REALTY_USAGE_TYPE,
			ResourceType.REALTY_PURPOSE,
			ResourceType.REALTY_TYPE,
		],
		forms: [
			BrokerageFormEnum.SIMULATOR_ADD_PROJECT_COSTS,
			BrokerageFormEnum.SIMULATOR_ADD_PROJECT_FINANCING,
			BrokerageFormEnum.SIMULATOR_ADD_PROJECT_REALTY,
		],
	},
	[Simulator.SimulatorStepEnum.BORROWER_DETAILS]: {
		...defaultSimulatorConfiguration[Simulator.SimulatorStepEnum.BORROWER_DETAILS],
		isMonthlyPaymentEstimatorRateLocked: true,
	},
};

export default configuration;
