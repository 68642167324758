import { Component, Input } from '@angular/core';

@Component({
	selector: 'oper-client-alert',
	styleUrls: ['./alert.component.scss'],
	template: `
		<div
			class="alert"
			[ngClass]="{
				'alert-size--xs': size === 'xs',
				'alert-size--s': size === 's',
				'alert-size--m': size === 'm',
				'alert-size--l': size === 'l',
				'alert-level--info': level === 'info',
				'alert-level--success': level === 'success',
				'alert-level--warning': level === 'warn',
				'alert-level--error': level === 'error'
			}"
		>
			<div *ngIf="icon" class="icon">
				<oper-client-fontawesome-icon [color]="level" [icon]="icon"></oper-client-fontawesome-icon>
			</div>
			<div class="content">
				<span class="amount" *ngIf="amount">{{ amount }}</span>
				<span clsdd="label">
					<ng-container *ngIf="translateKey">{{ translateKey | translate: translationParams }}</ng-container>
					<ng-container *ngIf="labelIsTranslationKey">{{ labelToDisplay | translate: translationParams }}</ng-container>
					<ng-container *ngIf="!labelIsTranslationKey">{{ labelToDisplay }}</ng-container>
					<ng-content></ng-content>
				</span>
			</div>
		</div>
	`,
})
export class AlertComponent {
	@Input() icon: string;
	@Input() size: 'xs' | 's' | 'm' | 'l' = 's';
	@Input() level: 'info' | 'success' | 'warn' | 'error' = 'info';
	@Input() amount: string;
	@Input() translationParams: { [key: string]: string };

	/**
	 * TODO: translateKey will be deprecated in the future and label will be used instead! @zoltanradics
	 */
	@Input() translateKey: string;

	/**
	 * Label will be the new input in the future @zoltanradics
	 */
	@Input() set label(value: string) {
		this.labelIsTranslationKey = value.startsWith('ç');
		this.labelToDisplay = value;
	}

	labelIsTranslationKey: boolean;
	labelToDisplay: string;
}
