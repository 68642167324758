export enum BrokerageFormEnum {
	SIMULATOR_ADD_PROJECT_COSTS = 'brokerageSimulatorAddProjectCosts',
	SIMULATOR_ADD_PROJECT_FINANCING = 'brokerageSimulatorAddProjectFinancing',
	SIMULATOR_ASSES_FINANCES_BORROWER = 'brokerageSimulatorAssesFinancesBorrower',
	SIMULATOR_CREATE_REQUEST_ASSIGN = 'brokerageSimulatorCreateRequestAssign',
	SIMULATOR_CREATE_BORROWER_DETAILS = 'brokerageSimulatorCreateBorrowerDetails',
	SIMULATOR_CREATE_REQUEST_EXTRA_COLLATERAL = 'brokerageSimulatorCreateRequestExtraCollateral',
	SIMULATOR_CREATE_REQUEST_PROPERTY_DETAILS = 'brokerageSimulatorCreateRequestPropertyDetails',
	SIMULATOR_ADD_PROJECT_REALTY = 'brokerageSimulatorAddProjectRealty',
}

export function getCalculatorFormName(calculator: string, type: 'Input' | 'Output'): string {
	return `brokerage${calculator.replace(/^\w/, (letter) => letter.toUpperCase())}Calculator${type}`;
}
