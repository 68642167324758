import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ConfigurationPortalAcceptanceRule, ConfigurationPortalBaseProduct } from '@oper-client/configuration-portal/common';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';

export default function (
	formData?: ConfigurationPortalAcceptanceRule,
	resources?: PartialNormalizedResource,
	acceptanceRuleNames?: string[],
	products?: ConfigurationPortalBaseProduct[]
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'function',
			label: 'ç.configurationPortal.acceptanceRule.function',
			value: formData?.function,
			options: acceptanceRuleNames?.map((creditProvider) => ({
				id: creditProvider,
				key: creditProvider,
			})),
			searchable: true,
			class: 'span12',
		}),
		new InputField({
			key: 'blocking',
			label: 'ç.configurationPortal.acceptanceRule.blocking',
			value: formData?.blocking,
			type: 'checkbox',
			class: 'span12',
		}),
		new InputField({
			key: 'postCalculation',
			label: 'ç.configurationPortal.acceptanceRule.postCalculation',
			value: formData?.postCalculation,
			type: 'checkbox',
			class: 'span12',
		}),
		new InputSelect({
			key: 'product.configurationId',
			value: formData?.product?.configurationId,
			label: 'ç.feature.offer.product.singular',
			defaultLabel: 'ç.feature.offer.product.singular',
			required: true,
			validators: [],
			options: products?.map((product) => ({
				key: product.name + ' - ' + product.creditProvider?.summary,
				id: product.configurationId,
			})),
			alreadySorted: true,
			searchable: true,
			class: 'span12',
		}),
	];
}
