import { FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';

export function deepClone(source: any): any {
	if (Array.isArray(source)) {
		return source.map((item) => deepClone(item));
	}
	if (source instanceof Date) {
		return new Date(source.getTime());
	}
	if (source && typeof source === 'object') {
		return Object.getOwnPropertyNames(source).reduce(
			(o, prop) => {
				o[prop] = deepClone(source[prop]);
				return o;
			},
			Object.create(Object.getPrototypeOf(source))
		);
	}
	return source;
}

export function generateGuid(): string {
	const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

export function getValue(obj: Record<string, any>, path: string, defVal = null): any {
	if (obj[path]) return obj[path];
	if (typeof path === 'string') {
		const _path = path.split('.');
		return _path.reduce((acc, key) => (acc?.[key] ? acc[key] : defVal), obj);
	}
	return defVal;
}

export function convertObservableToBehaviorSubject<T>(observable: Observable<T>, initValue: T): BehaviorSubject<T> {
	const subject = new BehaviorSubject(initValue);

	observable.subscribe(subject);

	return subject;
}

export function deepEquals(object1: any, object2: any): boolean {
	if (typeof object1 === 'undefined' || typeof object2 === 'undefined') return false;
	if (object1 === null || object2 === null) return false;
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !deepEquals(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}
	return true;
}

function isObject(object: any): boolean {
	return object != null && typeof object === 'object';
}

export function flattenObject(obj: any, parentKey = '', sep = '.'): { [key: string]: any } {
	let items: { [key: string]: any } = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const newKey = parentKey ? `${parentKey}${sep}${key}` : key;

			if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
				const nestedItems = flattenObject(obj[key], newKey, sep);
				items = { ...items, ...nestedItems };
			} else {
				items[newKey] = obj[key];
			}
		}
	}

	return items;
}

export function unflattenObject(flatObj: any, sep = '.'): any {
	const nestedObj: any = {};

	for (const key in flatObj) {
		if (Object.prototype.hasOwnProperty.call(flatObj, key)) {
			const keys = key.split(sep);
			keys.reduce((acc, part, index) => {
				if (index === keys.length - 1) {
					acc[part] = flatObj[key];
				} else {
					if (!acc[part]) {
						acc[part] = {};
					}
					return acc[part];
				}
			}, nestedObj);
		}
	}

	return nestedObj;
}

/**
 * Utility function to get the value of an object by key path
 * @param obj - the object
 * @param path - the key path
 * @returns the value of the key path or undefined if the key path does not exist
 */
export function getValueByKeyPath(obj: any, path: string): any {
	path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	path = path.replace(/^\./, ''); // strip a leading dot
	const a = path.split('.');
	for (let i = 0, n = a.length; i < n; ++i) {
		const k = a[i];
		if (k in obj) {
			obj = obj[k];
		} else {
			return;
		}
	}
	return obj;
}

/**
 * Utility function to remove the empty (null/undefined/empty string/empty object) values from an object recursively.
 * @param obj - the object
 * @returns the object without the empty values
 */
export function removeNullValues<T>(obj: T): Partial<T> {
	return Object.entries(obj).reduce((acc, [key, value]) => {
		if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
			return acc;
		}

		if (typeof value === 'object') {
			const cleanedValue = removeNullValues(value);
			if (Object.keys(cleanedValue).length > 0) {
				return { ...acc, [key]: cleanedValue };
			}
		} else {
			return { ...acc, [key]: value };
		}

		return acc;
	}, {} as Partial<T>);
}

/**
 * Utility function to get the form value from valid form controls in a form group
 * @param formGroup - the form group
 * @returns the form value with only the valid form controls that have a value
 */
export function getValidFormValue<T>(formGroup: FormGroup): T {
	const formValue = Object.entries(formGroup.controls).reduce((acc, [key, control]) => {
		return control.valid ? { ...acc, [key]: control.value } : acc;
	}, {});

	return removeNullValues(formValue) as T;
}

export function updateNestedProperty(obj: any, fieldPath: string, value: any): any {
	const fields = fieldPath.split('.');
	const lastField = fields.pop();
	const newObj = { ...obj };
	
	const target = fields.reduce((acc, field) => {
		acc[field] = { ...acc[field] };
		return acc[field];
	}, newObj);
	

	if (lastField) {
		target[lastField] = value;
	}
	
	return newObj;
}