import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UtilFormattingModule } from '@oper-client/shared/util-formatting';
import { UiModule } from '@oper-client/ui';

import { ValidatorService } from './services/validator.service';

import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './components/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormModalComponent } from './components/dynamic-form-modal/dynamic-form-modal.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TooltipModule } from 'ng2-tooltip-directive';
import { DynamicFormSectionComponent } from './components/dynamic-form-section/dynamic-form-section.component';
import { matDateFormatsFactory } from '@oper-client/shared/util-bootstrap';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DynamicInputTableComponent } from './components/dynamic-input-table/dynamic-input-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		UtilFormattingModule,
		MatDatepickerModule,
		FontAwesomeModule,
		UiModule,
		NgSelectModule,
		TooltipModule,
	],
	declarations: [
		DynamicFormComponent,
		DynamicFormQuestionComponent,
		DynamicFormModalComponent,
		DynamicFormSectionComponent,
		DynamicInputTableComponent,
	],
	exports: [DynamicFormComponent, DynamicFormQuestionComponent, DynamicFormModalComponent, DynamicInputTableComponent],
	providers: [
		ValidatorService,
		{ provide: MAT_DATE_LOCALE, useFactory: (locale: string) => locale, deps: [LOCALE_ID] },
		{ provide: MAT_DATE_FORMATS, useFactory: matDateFormatsFactory, deps: [LOCALE_ID] },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
	],
})
export class DynamicFormModule {}
