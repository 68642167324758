import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CustomersEnum } from '../constants/customers.enum';
import { Simulator } from '@oper-client/shared/data-model';
import defaultSimulatorConfiguration from '../config/advisor-simulator/default-simulator.configuration';
import huSimulatorConfiguration from '../config/advisor-simulator/hu-simulator.configuration';
import atSimulatorConfiguration from '../config/advisor-simulator/at-simulator.configuration';

@Injectable({
	providedIn: 'root',
})
export class AdvisorSimulatorConfigurationService {
	getCustomerConfiguration(customer: CustomersEnum): Observable<Simulator.CustomerConfiguration> {
		switch (customer) {
			case CustomersEnum.FINPORTA:
				return of(huSimulatorConfiguration);
			case CustomersEnum.HYPOOOE:
			case CustomersEnum.AUSTRIA:
				return of(atSimulatorConfiguration);
			case CustomersEnum.GERMAN:
				return of(atSimulatorConfiguration);
			default:
				return of(defaultSimulatorConfiguration);
		}
	}
}
