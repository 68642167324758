import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as MortgageSimulatorReducer from './+state/mortgage-simulator.reducer';
import { MortgageSimulatorEffects } from './+state/mortgage-simulator.effects';
import { MortgageSimulatorFacade } from './+state/mortgage-simulator.facade';

import * as AdvisorSimulatorReducer from './+state/advisor-simulator/advisor-simulator.reducer';
import { AdvisorSimulatorEffects } from './+state/advisor-simulator/advisor-simulator.effects';
import { AdvisorSimulatorFacade } from './+state/advisor-simulator/advisor-simulator.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(MortgageSimulatorReducer.MORTGAGE_SIMULATOR_KEY, MortgageSimulatorReducer.reducer),
		StoreModule.forFeature(AdvisorSimulatorReducer.ADVISOR_SIMULATOR_KEY, AdvisorSimulatorReducer.reducer),
		EffectsModule.forFeature([MortgageSimulatorEffects, AdvisorSimulatorEffects]),
	],
	providers: [MortgageSimulatorFacade, AdvisorSimulatorFacade],
})
export class DataAccessMortgageSimulatorModule {}
