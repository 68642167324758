import { CreditProvider, Liability, PartialNormalizedResource, Periodicity, ProductDurationConfig } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { SelectOption } from '@oper-client/shared/util-data-model-transform';

export default function (
	formData?: Partial<Liability>,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[],
	durationConfig?: Partial<ProductDurationConfig & { periodicity?: Periodicity }>,
	clients?: SelectOption[]
): InputBase<any>[] {
	return [
		...(durationConfig.periodicity === Periodicity.YEARLY
			? [
					new InputField({
						key: 'amount',
						label: 'ç.question.amountYearly.label',
						value: formData?.amount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
					}),
				]
			: [
					new InputField({
						key: 'monthlyAmount',
						label: 'ç.question.amountMonthly.label',
						value: formData?.monthlyAmount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
					}),
				]),
		new InputSelect({
			key: 'client.id',
			label: 'ç.question.client.label',
			value: formData?.client?.id,
			required: false,
			options: clients,
			class: 'span6',
		}),
	];
}
