<header class="header">
	@for (headerDefinition of headerDefinitions; track $index) {
		@if (headerDefinition.class && headerDefinition.class !== 'hidden') {
			<div class="header__item" [ngClass]="headerDefinition.class">
				<div class="header__label">
					{{ headerDefinition.label | translate }}
				</div>

				@if (headerDefinition.helpText) {
					<oper-client-fontawesome-icon
						class="header__icon"
						[tooltip]="headerDefinition.helpText | async | translate"
						[hideDelay]="0"
						[tooltipClass]="'tooltip-break-word'"
						[icon]="'faCircleQuestion'"
					/>
				}
			</div>
		}
	}

	<div class="header__actions"></div>
</header>

<div class="rows">
	<div class="row" *ngFor="let row of rows; let i = index">
		<oper-client-dynamic-form
			class="row__form"
			[formConfiguration]="row"
			(valueChange)="formChanged$.next({ index: i, form: $event })"
		/>
		<oper-client-fontawesome-icon
			class="row__icon"
			[ngClass]="{ disabled }"
			[icon]="'faMinus'"
			[size]="'lg'"
			(click)="!disabled && removeRow$.next(i)"
		/>
	</div>
</div>

<button *ngIf="!disabled" class="add-row-button" [disabled]="hasInvalidForm$ | async" (click)="addRow.emit()">
	<oper-client-fontawesome-icon [icon]="'faPlus'" />
	{{ addRowLabel | translate }}
</button>
