import { Email, I18nPhoneNumber, PartialNormalizedResource, PhoneNumber, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { InputField, InputPhone } from '../../models/input-types.model';
import { ValidatorService } from '../../services/validator.service';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData: Partial<Simulator.Borrower> | null = null,
	resources?: PartialNormalizedResource,
	defaultLanguage?: string
): InputBase<any>[] {
	const email: Email = (formData?.emails?.length && formData?.emails[0]) || <Email>{};
	const mainPhoneNumber: PhoneNumber =
		formData?.phoneNumbers?.find((item) => item.phoneType?.definition === 'main') ||
		(formData?.phoneNumbers?.length === 1 &&
			formData?.phoneNumbers[0]?.phoneType?.definition !== 'landline' &&
			formData?.phoneNumbers[0]) ||
		<PhoneNumber>{};

	const questions = [
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName ?? null,
			type: 'text',
			required: true,
			onlyLetters: true,
			class: 'span6',
		}),
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName ?? null,
			type: 'text',
			required: true,
			onlyLetters: true,
			class: 'span6',
		}),
		new InputField({
			key: 'emails[0].value',
			label: 'ç.question.email.label',
			value: email?.value ?? '',
			type: 'text',
			required: true,
			validators: [ValidatorService.trimmedEmailValidator],
			class: 'span6',
		}),
		new InputPhone({
			key: 'phoneNumbers[0]',
			label: 'ç.question.mobileNumber.label',
			value: mainPhoneNumber as I18nPhoneNumber,
			allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
			required: true,
			validators: [ValidatorService.phoneNumberRequiredValidator()],
			class: 'span6',
			allowedTypes: ['MOBILE'],
		}),
	];

	return questions;
}
