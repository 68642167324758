import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
	BaseProductDiscounts,
	BorrowerUploadDocumentType,
	Offer,
	Product,
	ProductSearchFilterParams,
	ProductSortableKeys,
	SelectedBaseProductDiscounts,
	Simulator,
	UniqueProduct,
} from '@oper-client/shared/data-model';
import { SortOrder } from '@oper-client/shared/util-array';

export const calculateSimulation = createAction(
	'[Advisor Simulator] Calculate Simulation',
	props<{ payload: Simulator.CalculateSimulationPayload }>()
);

export const calculateSimulationSuccess = createAction(
	'[Advisor Simulator] Calculate Simulation Success',
	props<{ result: Simulator.CalculatedSimulationResult; payload: Partial<Simulator.CalculateSimulationPayload> }>()
);

export const calculateSimulationFailure = createAction(
	'[Advisor Simulator] Calculate simulation Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadSimulations = createAction(
	'[Advisor Simulator] Load Simulations',
	props<{ queryParams: Partial<Simulator.SearchSimulationQueryParams>; ordering: SortOrder<string>[] }>()
);

export const loadSimulationsSuccess = createAction(
	'[Advisor Simulator] Load Simulations Success',
	props<{ response: Simulator.SearchSimulationResponse }>()
);

export const loadSimulationsFailure = createAction('[Advisor Simulator] Load Simulations Failure', props<{ error: HttpErrorResponse }>());

export const loadSimulation = createAction('[Advisor Simulator] Load Simulation', props<{ simulationId: number }>());

export const loadSimulationSuccess = createAction(
	'[Advisor Simulator] Load Simulation Success',
	props<{ simulation: Simulator.Simulation }>()
);

export const loadSimulationFailure = createAction('[Advisor Simulator] Load Simulation Failure', props<{ error: HttpErrorResponse }>());

export const createSimulation = createAction(
	'[Advisor Simulator] Create Simulation',
	props<{ simulation: Partial<Simulator.Simulation> }>()
);

export const createSimulationSuccess = createAction(
	'[Advisor Simulator] Create Simulation Success',
	props<{ simulation: Simulator.Simulation }>()
);

export const createSimulationFailure = createAction('[Advisor Simulator] Create simulation Failure', props<{ error: HttpErrorResponse }>());

export const updateSimulation = createAction(
	'[Advisor Simulator] Update Simulation',
	props<{ simulationId: number; simulation: Partial<Simulator.Simulation> }>()
);

export const updateSimulationSuccess = createAction(
	'[Advisor Simulator] Update Simulation Success',
	props<{ simulation: Simulator.Simulation }>()
);

export const updateSimulationFailure = createAction('[Advisor Simulator] Update simulation Failure', props<{ error: HttpErrorResponse }>());

export const removeSimulation = createAction('[Advisor Simulator] Remove Simulation', props<{ simulationId: number }>());

export const removeSimulationSuccess = createAction('[Advisor Simulator] Remove Simulation Success', props<{ simulationId: number }>());

export const removeSimulationFailure = createAction('[Advisor Simulator] Remove Simulation Failure', props<{ error: HttpErrorResponse }>());

export const cleanUpSimulations = createAction('[Advisor Simulator] Clean Up Simulations');

export const cleanUpSimulationsSuccess = createAction('[Advisor Simulator] Clean Up Simulations Success');

export const cleanUpSimulationsFailure = createAction(
	'[Advisor Simulator] Clean Up Simulations Failure',
	props<{ error: HttpErrorResponse }>()
);

export const convertSimulationToLoanRequest = createAction(
	'[Advisor Simulator] Convert Simulation To Loan Request',
	props<{ payload: Simulator.ConvertToLoanRequestPayload }>()
);

export const convertSimulationToLoanRequestSuccess = createAction(
	'[Advisor Simulator] Convert Simulation To Loan Request Success',
	props<{ response: Simulator.ConvertSimulationToLoanRequestResponse }>()
);

export const convertSimulationToLoanRequestFailure = createAction(
	'[Advisor Simulator] Convert Simulation To Loan Request Failure',
	props<{ error: HttpErrorResponse }>()
);

export const setActiveSimulatorStep = createAction(
	'[Advisor Simulator] Set Active Simulator Step',
	props<{ step: Simulator.SimulatorStepEnum }>()
);
export const setCurrentSimulation = createAction(
	'[Advisor Simulator] Set Current Simulation',
	props<{ simulation: Simulator.Simulation }>()
);

export const clearCurrentSimulation = createAction('[Advisor Simulator] Clear Current Simulation');

export const clearActiveSimulatorStep = createAction('[Advisor Simulator] Clear Active Simulator Step');

export const setProductSearchFilters = createAction(
	'[Advisor Simulator] Set Product Search Filters',
	props<{ filters: ProductSearchFilterParams }>()
);

export const clearProductSearchFilters = createAction('[Advisor Simulator] Clear Product Search Filters');

export const hideInformationBox = createAction(
	'[Advisor Simulator] Hide Simulator Step Information Box',
	props<{ step: Simulator.SimulatorStepEnum }>()
);

export const loadProducts = createAction(
	'[Advisor Simulator] Load Products',
	props<{ payload: Partial<Simulator.SearchProductsPayload>; filters: ProductSearchFilterParams }>()
);

export const loadProductsSuccess = createAction('[Advisor Simulator] Load Products Success', props<{ products: Product[] }>());

export const loadProductsFailure = createAction('[Advisor Simulator] Load Products Failure', props<{ error: HttpErrorResponse }>());

export const selectAllProducts = createAction('[Advisor Simulator] Select All Products');

export const unselectAllProducts = createAction('[Advisor Simulator] Unselect All Products');

export const selectProducts = createAction('[Advisor Simulator] Select Products', props<{ products: UniqueProduct[] }>());

export const unselectProducts = createAction('[Advisor Simulator] Unselect Products', props<{ products: UniqueProduct[] }>());

export const selectProduct = createAction('[Advisor Simulator] Select Product', props<{ product: UniqueProduct }>());

export const unselectProduct = createAction('[Advisor Simulator] Unselect Product', props<{ product: UniqueProduct }>());

export const clearProducts = createAction('[Advisor Simulator] Clear Products');

export const sortProductsByKey = createAction('[Advisor Simulator] Sort Products By Key', props<{ sortableKey: ProductSortableKeys }>());

export const clearProductsSortOrder = createAction('[Advisor Simulator] Clear Products Sort Order');

export const setSimulationsSortOrder = createAction(
	'[Advisor Simulator] Set Simulations Sort Order',
	props<{ ordering: SortOrder<string>[] }>()
);

export const clearSimulationsSortOrder = createAction('[Advisor Simulator] Clear Simulations Sort Order');

export const clearOffers = createAction('[Advisor Simulator] Clear Offers');

export const clearActions = createAction('[Advisor Simulator] Clear Actions');

export const loadOffers = createAction('[Advisor Simulator] Load Offers', props<{ payload: Partial<Simulator.LoadOffersPayload> }>());

export const loadOffersSuccess = createAction('[Advisor Simulator] Load Offers Success', props<{ offers: Partial<Offer>[] }>());

export const loadOffersFailure = createAction('[Advisor Simulator] Load Offers Failure', props<{ error: HttpErrorResponse }>());

export const updateOffer = createAction('[Advisor Simulator] Update Offer', props<{ payload: Partial<Simulator.UpdateOfferPayload> }>());

export const updateOfferSuccess = createAction('[Advisor Simulator] Update Offer Success', props<{ offer: Partial<Offer> }>());

export const updateOfferFailure = createAction('[Advisor Simulator] Update Offer Failure', props<{ error: HttpErrorResponse }>());

export const selectAllOffers = createAction('[Advisor Simulator] Select All Offers');

export const unselectAllOffers = createAction('[Advisor Simulator] Unselect All Offers');

export const selectOffer = createAction('[Advisor Simulator] Select Offer', props<{ offerId: number }>());

export const unselectOffer = createAction('[Advisor Simulator] Unselect Offer', props<{ offerId: number }>());

export const removeOffer = createAction('[Advisor Simulator] Remove Offer', props<{ offerId: number; creditProviderId: number }>());

export const removeOffers = createAction('[Advisor Simulator] Remove Offers', props<{ offerIds: number[]; creditProviderIds: number[] }>());

export const syncSelectedProducts = createAction(
	'[Advisor Simulator] Sync Selected Products with the latest changes',
	props<{ products: Product[] }>()
);

export const loadDiscounts = createAction(
	'[Advisor Simulator] Load Discounts',
	props<{ payload: Partial<Simulator.LoadDiscountsPayload>; selectDefaultDiscounts: boolean }>()
);

export const loadDiscountsSuccess = createAction(
	'[Advisor Simulator] Load Discounts Success',
	props<{ discounts: BaseProductDiscounts[] }>()
);

export const loadDiscountsFailure = createAction('[Advisor Simulator] Load Discounts Failure', props<{ error: HttpErrorResponse }>());

export const clearDiscounts = createAction('[Advisor Simulator] Clear Discounts');

export const setSelectedDiscounts = createAction(
	'[Advisor Simulator] Set Selected Discounts',
	props<{ discounts: SelectedBaseProductDiscounts[] }>()
);

export const cancelPendingHttpRequests = createAction('[Advisor Simulator] Cancelling Pending HTTP Requests');

export const loadConfiguration = createAction('[Advisor Simulator] Load Configuration', props<{ customer: string }>());

export const loadConfigurationSuccess = createAction(
	'[Advisor Simulator] Load Configuration Success',
	props<{ configuration: Simulator.CustomerConfiguration }>()
);

export const loadConfigurationFailure = createAction(
	'[Advisor Simulator] Load Configuration Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadBorrowerDocumentTypes = createAction('[Advisor Simulator] Load Borrower Document Types');

export const loadBorrowerDocumentTypesSuccess = createAction(
	'[Advisor Simulator] Load Borrower Document Types Success',
	props<{ documents: BorrowerUploadDocumentType[] }>()
);

export const loadBorrowerDocumentTypesFailure = createAction(
	'[Advisor Simulator] Load Borrower Document Types Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadMoreSimulations = createAction(
	'[Advisor Simulator] Load More Simulations',
	props<{ searchResponse: Simulator.SearchSimulationResponse; ordering: SortOrder<string>[] }>()
);

export const loadMoreSimulationsSuccess = createAction(
	'[Advisor Simulator] Load More Simulations Success',
	props<{ response: Simulator.SearchSimulationResponse }>()
);

export const loadMoreSimulationsFailure = createAction(
	'[Advisor Simulator] Load More Simulations Failure',
	props<{ error: HttpErrorResponse }>()
);

export const clearSimulations = createAction('[Advisor Simulator] Clear Simulations');

export const setAssignAnalystDialogVisibility = createAction(
	'[Advisor Simulator] Set Assign Analyst Dialog Visibility',
	props<{ visibility: boolean }>()
);

export const setViewMode = createAction('[Advisor Simulator] Set View Mode', props<{ viewMode: Simulator.ViewMode }>());
