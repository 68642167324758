import { Step, StepNames } from '@oper-client/shared/data-model';

export const getOfficeSelectorStep = (next: StepNames) =>
	<Step>{
		name: StepNames.OFFICE_SELECTOR,
		next,
		back: null,
		title: 'ç.feature.selfService.steps.officeSelector.title',
		description: 'ç.feature.selfService.steps.officeSelector.description',
		disclaimer: false,
		nextLabel: 'ç.misc.action.next',
		backLabel: 'ç.misc.action.back',
		required: true,
	};

export const getDefaultConfig = (zeroStepName: StepNames = null) =>
	<Step[]>[
		{
			name: StepNames.BORROWER_MODE,
			next: StepNames.CLIENT,
			back: zeroStepName,
			title: 'ç.feature.selfService.steps.borrower_mode.title',
			description: 'ç.feature.selfService.steps.borrower_mode.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
		},
		{
			name: StepNames.CLIENT,
			next: StepNames.INCOME,
			back: StepNames.BORROWER_MODE,
			title: 'ç.feature.selfService.steps.client.title',
			description: 'ç.feature.selfService.steps.client.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
		},
		{
			name: StepNames.INCOME,
			next: StepNames.LIABILITY,
			back: StepNames.CLIENT,
			title: 'ç.feature.selfService.steps.income.title',
			description: 'ç.feature.selfService.steps.income.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
		},
		{
			name: StepNames.LIABILITY,
			next: StepNames.OWN_FUNDS,
			back: StepNames.INCOME,
			title: 'ç.feature.selfService.steps.liability.title',
			description: 'ç.feature.selfService.steps.liability.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
		},
		{
			name: StepNames.OWN_FUNDS,
			next: StepNames.REALTY,
			back: StepNames.LIABILITY,
			title: 'ç.feature.selfService.steps.financial-asset.title',
			description: 'ç.feature.selfService.steps.financial-asset.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
		},
		{
			name: StepNames.REALTY,
			next: StepNames.PREAPPROVAL,
			back: StepNames.OWN_FUNDS,
			title: 'ç.feature.selfService.steps.realty.title',
			description: 'ç.feature.selfService.steps.realty.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
		},
		{
			name: StepNames.PREAPPROVAL,
			next: null,
			back: StepNames.REALTY,
			title: 'ç.feature.selfService.steps.preapproval.title',
			description: 'ç.feature.selfService.steps.preapproval.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
		},
	];

export const extendFetchedConfigWithZeroStep = (zeroStepName: StepNames = null, config) =>
	<Step[]>config.map((step, index) => (index === 0 ? { ...step, back: zeroStepName } : step));
