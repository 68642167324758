import { Step, StepError } from './application.model';

export interface StepConfigValidator {
	message: string;

	validate(steps: Step[]): StepError[];
}

export class StepNameValidator implements StepConfigValidator {
	message = 'ç.misc.applicationFlow.missingStepName';

	validate(steps: Step[]) {
		const errors = <StepError[]>[];
		steps.forEach((step, index) => {
			if (!steps[index].name) {
				errors.push({ error: this.message, step, index: index + 1 });
			}
		});
		return errors;
	}
}

export class StepHasNextValidator implements StepConfigValidator {
	message = 'ç.misc.applicationFlow.nextNotDefined';

	validate(steps: Step[]) {
		const errors = <StepError[]>[];
		steps.forEach((step, index) => {
			if (!steps[index].next) {
				errors.push({ error: this.message, step, index: index + 1 });
			}
		});
		return errors;
	}
}

export class StepNextStepValidator implements StepConfigValidator {
	message = 'ç.misc.applicationFlow.invalidNextStep';

	validate(steps: Step[]) {
		const errors = <StepError[]>[];
		steps.forEach((step, index) => {
			const isLastStep = index == steps.length - 1;
			if (!isLastStep && steps[index].next !== steps[index + 1].name) {
				errors.push({ error: this.message, step, index: index + 1 });
			}
		});
		return errors;
	}
}
