import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import {
	DocumentResponse,
	ExcelPricerPayload,
	ExcelPricerResponse,
	LibraryDocumentPayload,
	LibraryDocumentResponse,
	ConfigurationPortalPricingSheet,
	PricingSheetProduct,
} from '@oper-client/configuration-portal/common';
import { HttpHeaders } from '@angular/common/http';
import { CreditProvider } from '@oper-client/shared/data-model';

export const CONFIGURATION_PORTAL_PRICING_SERVICE = new InjectionToken<PricingService>('CONFIGURATION_PORTAL_PRICING_SERVICE');

@Injectable()
export class PricingService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getDocumentTypes(): Observable<any> {
		return this.apiService.get('/configuration/resources/library-document-type/');
	}

	getDocumentType(definition: string): Observable<any> {
		return this.apiService.get('/configuration/resources/library-document-type/?search=' + definition);
	}

	getLanguages(): Observable<any> {
		return this.apiService.get('/configuration/resources/language/');
	}

	getPricingSheets(): Observable<ConfigurationPortalPricingSheet[]> {
		return this.apiService.get('/configuration/pricingmodule/pricing-sheets/');
	}

	getPricingSheet(configurationId: string): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.get('/configuration/pricingmodule/pricing-sheets/' + configurationId);
	}

	getProducts(): Observable<PricingSheetProduct[]> {
		return this.apiService.get('/configuration/creditprovidermodule/base-products/');
	}

	getProduct(configurationId: string): Observable<PricingSheetProduct> {
		return this.apiService.get('/configuration/creditprovidermodule/base-products/' + configurationId);
	}

	createPricingSheet(pricingSheet: ConfigurationPortalPricingSheet): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.post('/configuration/pricingmodule/pricing-sheets/', pricingSheet);
	}

	updatePricingSheet(pricingSheet: ConfigurationPortalPricingSheet): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.put('/configuration/pricingmodule/pricing-sheets/' + pricingSheet.configurationId, pricingSheet);
	}

	uploadExcelPricer(payload: ExcelPricerPayload): Observable<ExcelPricerResponse> {
		const formData = new FormData();
		formData.append('file_name', payload.fileName || '');
		formData.append('file', payload.file, payload.fileName || '');
		const headers = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.post('/api/internaldocuments/upload/', formData, null, headers);
	}

	downloadExcelPricer(configurationId: string): Observable<DocumentResponse> {
		return this.apiService.get(`/api/internaldocuments/documents/${configurationId}`);
	}

	deleteExcelPricer(configurationId: string): Observable<DocumentResponse> {
		return this.apiService.delete(`/api/internaldocuments/documents/${configurationId}`);
	}

	downloadLibraryDocument(configurationId: string): Observable<LibraryDocumentResponse> {
		return this.apiService.get(`/configuration/documentationlibrarymodule/library-documents/${configurationId}`);
	}

	createLibraryDocument(
		payload: LibraryDocumentPayload,
		creditProvider: CreditProvider | undefined
	): Observable<LibraryDocumentResponse> {
		const body = {
			document_type: {
				summary: payload.documentType.summary,
				configuration_id: payload.documentType.configurationId,
			},
			language: {
				summary: payload.language.summary,
				configuration_id: payload.language.configurationId,
			},
			credit_provider: {
				summary: creditProvider.summary,
				configuration_id: creditProvider.configurationId,
			},
		};
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.apiService.post('/configuration/documentationlibrarymodule/library-documents/', body, null, headers);
	}

	uploadDocumentFile(configurationId: string, payload: LibraryDocumentPayload): Observable<any> {
		const formData = new FormData();
		formData.append('file_name', payload.fileName + '.' + payload.extension || 'no-name');
		formData.append('file', payload.file, payload.fileName || 'no-name');
		const fileHeaders = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.post(
			`/configuration/documentationlibrarymodule/library-documents/${configurationId}/upload/`,
			formData,
			null,
			fileHeaders
		);
	}

	uploadLibraryDocument(payload: LibraryDocumentPayload, creditProvider: CreditProvider | undefined): Observable<any> {
		return this.createLibraryDocument(payload, creditProvider).pipe(
			switchMap((response: LibraryDocumentResponse) => this.uploadDocumentFile(response.configurationId, payload))
		);
	}

	deleteDocumentByConfigurationId(configurationId: string) {
		return this.apiService.delete(`/configuration/documentationlibrarymodule/library-documents/${configurationId}/`, null);
	}

	deactivateDocumentByConfigurationId(configurationId: string) {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.apiService.patch(
			`/configuration/documentationlibrarymodule/library-documents/${configurationId}/`,
			{
				active: false,
			},
			null,
			headers
		);
	}

	editLibraryDocument(configurationId: string, payload: LibraryDocumentPayload): Observable<LibraryDocumentResponse> {
		const body = {
			language: {
				summary: payload.language.summary,
				configuration_id: payload.language.configurationId,
			},
		};
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.apiService.patch(`/configuration/documentationlibrarymodule/library-documents/${configurationId}`, body, null, headers);
	}

	editDocumentFile(configurationId: string, payload: LibraryDocumentPayload): Observable<any> {
		const formData = new FormData();
		if (payload.fileName) {
			formData.append('file_name', payload.fileName);
		}
		const fileHeaders = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.patch(
			`/configuration/documentationlibrarymodule/library-documents/${configurationId}/upload/`,
			formData,
			null,
			fileHeaders
		);
	}

	patchLibraryDocument(configurationId: string, payload: LibraryDocumentPayload): Observable<any> {
		return this.editLibraryDocument(configurationId, payload).pipe(
			switchMap((response: LibraryDocumentResponse) => this.editDocumentFile(response.configurationId, payload))
		);
	}
}
