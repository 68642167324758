import { Inject, Injectable, signal } from '@angular/core';
import { API_SERVICE, IAiValidationServiceService, IApiService } from '@oper-client/shared/data-access';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AiValidationFieldMappings, AnyObject } from '@oper-client/shared/data-model';

@Injectable()
export class AiValidationService implements IAiValidationServiceService {
	$validationResults = signal([]);

	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getConfig(): Observable<{ fieldMapping: AiValidationFieldMappings; supportedDocuments: string[] }> {
		return of({
			fieldMapping: {
				firstName: {
					section: 'client',
					subSection: 'identity',
					propertyPath: 'firstName',
				},
				middleName: {
					section: 'client',
					subSection: 'identity',
					propertyPath: 'middleName',
				},
				lastName: {
					section: 'client',
					subSection: 'identity',
					propertyPath: 'lastName',
				},
				street: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'employer.address.street',
				},
				houseNumber: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'employer.address.houseNumber',
				},
				zipCode: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'employer.address.zipCode',
				},
				countryCode: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'employer.address.country.definition',
					resource: 'country'
				},
				city: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'employer.address.city',
				},
				startDate: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'startDate',
				},
				employerName: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'employer.name',
				},
				contractType: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'contractType.definition',
					resource: 'employment-contract-type'
				},
				grossMonthlyIncome: {
					section: 'client',
					subSection: 'income',
					propertyPath: 'amount',
				},
				birthDate: {
					section: 'client',
					subSection: 'identity',
					propertyPath: 'birthDate',
				},
				idCardNumber: {
					section: 'client',
					subSection: 'identity',
					propertyPath: 'idCardNumber',
				},
			},
			supportedDocuments: ['payslip'],
		});
	}

	triggerValidation(
		loanRequestId: number,
		proofId: number,
		docId: number
	): Observable<{
		id: string;
		status: string;
	}> {
		return this.apiService
			.post(`/api/loan-requests/${loanRequestId}/proof/${proofId}/documents/${docId}/ai_verifications/`)
			.pipe(map((res) => ({ id: res.celeryTaskId, status: res.status })));
	}

	getValidationResult(
		loanRequestId: number,
		proofId: number,
		docId: number,
		validationId: string
	): Observable<{
		id: string;
		status: string;
		payload: AnyObject<string>;
	}> {
		const existingResult = this.$validationResults().find((el) => el.id === validationId);
		if (existingResult && existingResult.status === 'finished') {
			return of(existingResult);
		}
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/${proofId}/documents/${docId}/ai_verifications`).pipe(
			tap((res) => this.$validationResults.set(res)),
			map((res) => {
				const searchedRes = res.find((item) => item.celeryTaskId === validationId);
				return searchedRes
					? {
							id: searchedRes.celeryTaskId,
							status: searchedRes.status,
							payload: searchedRes.payload,
						}
					: null;
			})
		);
	}

	clearValidationResults() {
		this.$validationResults.set([]);
	}
}
