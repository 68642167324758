export enum CustomersEnum {
	FINPORTA = 'finporta',
	AUSTRIA = 'austria',
	BELGIAN = 'belgian',
	CLER = 'cler',
	CREAFIN = 'creafin',
	CREDISHOP = 'credishop',
	FINTEAM = 'finteam',
	GERMAN = 'german',
	HILLEWAERE = 'hillewaere',
	HYPOEXPERT = 'hypoexpert',
	HYPONATION = 'hyponation',
	HYPOOOE = 'hypoooe',
	IFINCO = 'ifinco',
	OPER = 'oper',
	SWISS = 'swiss',
	MEDIRECT = 'medirect',
	TRIODOS = 'triodos',
	UK = 'uk',
	WILINK = 'wilink',
	VOLKSBANK = 'volksbank',
	VOLKSBANKWIEN = 'volksbankwien',
	VOLKSBANKTIROL = 'volksbanktirol',
}
