<div class="phone-input-container input-group">
	<div class="tel-input-wrapper">
		<button
			(keydown.arrowDown)="onBtnKeyPress($event)"
			type="button"
			[matMenuTriggerFor]="menu"
			class="country-selector"
			[disabled]="disabled"
		>
			<span class="country-selector-flag flag" [ngClass]="selectedCountry?.flagClass"></span>
		</button>
		<mat-menu #menu="matMenu" yPosition="below">
			<input
				*ngIf="enableSearch"
				class="country-search"
				[(ngModel)]="searchCriteria"
				(ngModelChange)="searchChanged()"
				type="text"
				[placeholder]="searchPlaceholder | translate"
				(click)="$event.stopPropagation()"
				(keydown)="countrySearchKeydown($event)"
			/>
			<button
				*ngFor="let country of filteredCountries"
				mat-menu-item
				(click)="onCountrySelect(country, focusable)"
				class="country-list-button"
			>
				<div class="icon-wrapper">
					<div class="flag" [ngClass]="country.flagClass"></div>
				</div>
				<div class="label-wrapper">{{ country.iso2.toUpperCase() }} {{ country.definition }}</div>
			</button>
		</mat-menu>
		<span class="country-selector-code" *ngIf="selectedCountry?.definition">{{ selectedCountry.definition }}</span>
		<input
			matInput
			type="tel"
			autocomplete="off"
			[ngClass]="cssClass"
			(blur)="onTouched()"
			(keypress)="onKeyPress($event)"
			[(ngModel)]="phoneNumber"
			(ngModelChange)="onPhoneNumberChange()"
			[errorStateMatcher]="errorStateMatcher"
			[placeholder]="inputPlaceholder"
			[disabled]="disabled"
			[aria-describedby]="describedBy"
			[required]="required"
			#focusable
		/>
	</div>
</div>
