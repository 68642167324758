import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
	BaseProductDiscounts,
	BorrowerUploadDocumentType,
	Offer,
	Product,
	ProductSearchFilterParams,
	Simulator,
} from '@oper-client/shared/data-model';
import { SortOrder } from '@oper-client/shared/util-array';

export const ADVISOR_SIMULATOR_SERVICE = new InjectionToken<IAdvisorSimulatorService>('ADVISOR_SIMULATOR_SERVICE');

export interface IAdvisorSimulatorService {
	convertSimulationToLoanRequest(
		payload: Simulator.ConvertToLoanRequestPayload
	): Observable<Simulator.ConvertSimulationToLoanRequestResponse>;
	loadDiscounts(payload: Partial<Simulator.LoadDiscountsPayload>, selectDefaultDiscounts: boolean): Observable<BaseProductDiscounts[]>;
	loadProducts(payload: Partial<Simulator.SearchProductsPayload>, filters?: ProductSearchFilterParams): Observable<Product[]>;
	calculateSimulation(payload: Simulator.CalculateSimulationPayload): Observable<Simulator.CalculatedSimulationResult>;
	createSimulation(request?: Partial<Simulator.Simulation>): Observable<Simulator.Simulation>;
	updateSimulation(simulationId: number, request: Partial<Simulator.Simulation>): Observable<Simulator.Simulation>;
	loadSimulations(
		queryParams: Partial<Simulator.SearchSimulationQueryParams>,
		ordering?: SortOrder<string>[]
	): Observable<Simulator.SearchSimulationResponse>;
	loadSimulation(simulationId: number): Observable<Simulator.Simulation>;
	removeSimulation(simulationId: number): Observable<void>;
	cleanUpSimulations(): Observable<void>;
	updateOffer(payload: Partial<Simulator.UpdateOfferPayload>): Observable<Partial<Offer>>;
	loadOffers(payload: Partial<Simulator.LoadOffersPayload>): Observable<Partial<Offer>[]>;
	loadBorrowerDocumentTypes(): Observable<BorrowerUploadDocumentType[]>;
}
