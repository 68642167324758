import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ConfigurationPortalPricingSheet, PricingSheetProduct } from '@oper-client/configuration-portal/common';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';

export default function (
	formData?: ConfigurationPortalPricingSheet,
	resources?: PartialNormalizedResource,
	products?: PricingSheetProduct[],
	readonlyMode = false
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'product.configurationId',
			value: formData?.product?.configurationId,
			label: 'ç.feature.offer.product.singular',
			defaultLabel: 'ç.feature.offer.product.singular',
			required: true,
			validators: [],
			options: products?.map((product) => ({
				key: product.name + ' - ' + product.creditProvider?.summary,
				id: product.configurationId,
			})),
			alreadySorted: true,
			class: 'span12',
			disabled: readonlyMode,
		}),
		new InputField({
			key: 'version',
			label: 'ç.question.version.label',
			value: formData?.version || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'validFrom',
			label: 'ç.feature.pricing.validFrom',
			value: formData?.validFrom,
			type: 'date',
			required: true,
			validators: [],
			class: 'span12',
		}),
		new InputField({
			key: 'referenceIndex',
			label: 'ç.question.referenceValue.label',
			value: '0',
			type: 'hidden',
			required: true,
			validators: [],
			class: 'span12',
		}),
	];
}
