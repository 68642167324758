import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';

import { Step, FinancialData, Resource, MortgageReportRequest, StepNames } from '@oper-client/shared/data-model';

import * as MortgageSimulatorActions from './mortgage-simulator.actions';
import * as MortgageSimulatorSelectors from './mortgage-simulator.selectors';

@Injectable()
export class MortgageSimulatorFacade {
	constructor(private store: Store<any>) {}

	public error$ = this.store.pipe(select(MortgageSimulatorSelectors.getError));
	public mortgageReportError$ = this.store.pipe(select(MortgageSimulatorSelectors.getMortgageReportError));
	public loadMortgageReportActionState$ = this.store.pipe(select(MortgageSimulatorSelectors.loadMortgageReportActionState));

	public configuration$ = this.store.pipe(select(MortgageSimulatorSelectors.getConfiguration));

	public stepperConfiguration$ = this.store.pipe(
		select(MortgageSimulatorSelectors.getConfiguration),
		map((steps) => steps?.filter((step) => !step?.hideInStepper))
	);

	public activeStep$ = this.store.pipe(
		select(MortgageSimulatorSelectors.getActiveStep),
		filter((activeStep) => activeStep !== null)
	);

	public simulationFlowType$ = this.store.pipe(select(MortgageSimulatorSelectors.getSimulationFlowType));

	public recalculationFormNames$ = this.store.pipe(
		select(MortgageSimulatorSelectors.getRecalculationFormNames),
		filter((fornNames) => fornNames !== null)
	);

	public fromQueryParam$ = this.store.pipe(select(MortgageSimulatorSelectors.getFromQueryParam));

	public loadingIndicator$ = this.store.pipe(select(MortgageSimulatorSelectors.getLoadingIndicator));

	public financialData$ = this.store.pipe(
		select(MortgageSimulatorSelectors.getFinancialData),
		filter((financialData) => financialData !== null)
	);

	public mortgageReport$ = this.store.pipe(
		select(MortgageSimulatorSelectors.getMortgageReport),
		filter((mortgageReport) => mortgageReport !== null)
	);

	public getMortgageReportError$ = this.store.pipe(
		select(MortgageSimulatorSelectors.getMortgageReportError),
		filter((mortgageReportError) => mortgageReportError !== null)
	);

	public hasMortgageReportError$ = this.store.pipe(
		select(MortgageSimulatorSelectors.getMortgageReportError),
		map((mortgageReportError) => Array.isArray(mortgageReportError) && mortgageReportError.length > 0)
	);

	public setActiveStep(step: Step): void {
		this.store.dispatch(MortgageSimulatorActions.setActiveStep({ step }));
	}

	public setConfiguration(configuration: Step[], activeStep?: Step, simulationFlowType?: Resource, fromQueryParam?: boolean): void {
		this.store.dispatch(
			MortgageSimulatorActions.setConfiguration({
				configuration,
				activeStep,
				simulationFlowType,
				fromQueryParam,
			})
		);
	}

	public showLoadingIndicator(): void {
		this.store.dispatch(MortgageSimulatorActions.showLoadingIndicator());
	}

	public hideLoadingIndicator(): void {
		this.store.dispatch(MortgageSimulatorActions.hideLoadingIndicator());
	}

	public nextStep(): void {
		this.store.dispatch(MortgageSimulatorActions.nextStep());
	}

	public prevStep(): void {
		this.store.dispatch(MortgageSimulatorActions.prevStep());
	}

	public showError(message: string): void {
		this.store.dispatch(MortgageSimulatorActions.showError({ message }));
	}

	public hideError(): void {
		this.store.dispatch(MortgageSimulatorActions.hideError({ message: null }));
	}

	public reset(): void {
		this.store.dispatch(MortgageSimulatorActions.reset());
	}

	public clearFinancialData(): void {
		this.store.dispatch(MortgageSimulatorActions.clearFinancialData());
	}

	public setFinancialData(financialData: Partial<FinancialData>): void {
		this.store.dispatch(MortgageSimulatorActions.setFinancialData({ financialData }));
	}

	public getMortgageReport(mortgageReportRequest: MortgageReportRequest): void {
		this.store.dispatch(MortgageSimulatorActions.loadMortgageReport({ mortgageReportRequest }));
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	public clear(): void {
		this.store.dispatch(MortgageSimulatorActions.clear());
	}

	public markStepForSkip(step: StepNames, skip: boolean) {
		this.store.dispatch(MortgageSimulatorActions.markStepForSkip({ step, skip }));
	}
}
