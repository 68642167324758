import { FormGroup } from '@angular/forms';
import { PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { DynamicInputTable, InputField, InputSelect, showOrHideField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { debounceTimes } from '@oper-client/shared/configuration';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	const loanPurposesSet = new Set(formData?.loanPurposes?.map((purpose) => purpose.definition.toLowerCase()));
	const hasNewBuildPurpose = loanPurposesSet.has('newbuild');
	const hasRenovationPurpose = loanPurposesSet.has('renovation');
	const hasPurchasePurpose = loanPurposesSet.has('purchase');
	const hasFreeUsePurpose = loanPurposesSet.has('freeuse');
	const renovationTypeId = resources?.[ResourceType.RENOVATION_TYPE]?.find((item) => item.definition === 'generic')?.id;
	const hasSingleRenovationType = resources?.[ResourceType.RENOVATION_TYPE]?.length === 1;

	let realtyPrice = 0;

	if (hasPurchasePurpose) {
		realtyPrice = hasRenovationPurpose ? formData?.fairMarketValue : formData?.realtyPrice;
	}

	const questions: InputBase<any>[] = [
		new InputField({
			key: 'realtyPrice',
			label: 'ç.question.fairMarketValue.label',
			value: realtyPrice,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'realtyPrice', 'number', hasPurchasePurpose && !hasRenovationPurpose, false),
		}),
		new InputField({
			key: 'fairMarketValue',
			label: 'ç.question.fairMarketValue.label',
			value: hasFreeUsePurpose || hasRenovationPurpose ? formData?.fairMarketValue : 0,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(
					formGroup,
					formConfiguration,
					'fairMarketValue',
					'number',
					hasFreeUsePurpose || hasRenovationPurpose,
					false
				),
		}),
		new InputField({
			key: 'freeUseMortgage',
			label: 'ç.resource.additional-financing-need-type.freePurposeMortgage',
			value: hasFreeUsePurpose ? formData?.freeUseMortgage : 0,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'freeUseMortgage', 'number', hasFreeUsePurpose, false),
		}),
		new InputField({
			key: 'priceOfLand',
			label: 'ç.question.priceLand.label',
			value: hasNewBuildPurpose ? formData?.priceOfLand : 0,
			type: 'number',
			readonly: readonlyMode,
			required: false,
			currency: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'priceOfLand', 'number', hasNewBuildPurpose, false),
		}),
		new InputField({
			key: 'buildingCosts',
			label: 'ç.question.priceBuilding.label',
			value: hasNewBuildPurpose ? formData?.buildingCosts : 0,
			type: 'number',
			readonly: readonlyMode,
			required: false,
			currency: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'buildingCosts', 'number', hasNewBuildPurpose, false),
		}),
	];

	if (hasSingleRenovationType) {
		questions.push(
			new InputField({
				key: 'renovationCosts[0].type.id',
				type: 'hidden',
				value: renovationTypeId,
			}),

			new InputField({
				key: 'renovationCosts[0].amount',
				label: 'ç.feature.realty.renovation.singular',
				value: hasRenovationPurpose ? formData?.renovationCosts[0]?.amount ?? 0 : 0,
				type: 'number',
				readonly: readonlyMode,
				required: false,
				currency: true,
				order: 1,
				class: 'span12',
				transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
					showOrHideField(formGroup, formConfiguration, 'renovationCosts[0].amount', 'number', hasRenovationPurpose, false),
			})
		);
	} else {
		questions.push(
			new DynamicInputTable({
				key: 'renovationCosts',
				value: hasRenovationPurpose ? formData?.renovationCosts : [],
				debounceTime: debounceTimes.s,
				required: false,
				addRowLabel: 'ç.feature.mortgageSimulator.addRenovation',
				label: 'ç.feature.mortgageSimulator.addRenovation',
				disabled: readonlyMode,
				order: 1,
				columns: [
					new InputSelect({
						key: 'type.id',
						required: true,
						clearable: true,
						disabled: readonlyMode,
						label: 'ç.question.renovationType.label',
						validators: [],
						options: resources?.[ResourceType.RENOVATION_TYPE] || [],
						alreadySorted: true,
						class: 'span8',
					}),
					new InputField({
						key: 'amount',
						type: 'number',
						disabled: readonlyMode,
						label: 'ç.question.renovationAmount.label',
						required: true,
						currency: true,
						class: 'span4',
					}),
				],
				transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
					showOrHideField(formGroup, formConfiguration, 'renovationCosts', 'table', hasRenovationPurpose, false),
			})
		);
	}

	return questions;
}
